import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout";
import SEO from "../components/seo";
import Section from "../components/section";
import MainHeading from "../components/main-heading";
import ImageText from "../components/image-text";

export default function Template({
  data, // this prop will be injected by the GraphQL query below.
}) {
  const { markdownRemark } = data // data.markdownRemark holds your post data
  const { frontmatter, html } = markdownRemark
  return (
    <Layout>
          <SEO
        title={frontmatter.title}
      />

        <Section>
            <MainHeading heading={frontmatter.title} section="Rozcestník">
              <p className="lg:w-2/3 mx-auto leading-relaxed text-base"></p>
            </MainHeading>
            <ImageText image={frontmatter.image.publicURL}>
              <p className="text-xl sm:text-3xl text-gray-600 font-bold text-center mx-4 my-8 lg:mx-16">{frontmatter.quote}</p>
            </ImageText>
            <div
              className="content max-w-screen-md mx-auto text-lg sm:text-xl"
              dangerouslySetInnerHTML={{ __html: html }}
            />
        </Section>
      </Layout>
  )
}
export const pageQuery = graphql`
  query($slug: String!) {
    markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      html
      frontmatter {
        slug
        title
        quote
        image {
          publicURL
        }
      }
    }
  }
`